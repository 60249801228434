import { pluralize } from '../formatters/pluralize';
import { TDurationKey } from '../formatters/duration';

export type TDurationLang =
  Record<TDurationKey, string | ((value: number) => string)>
  & { decimals: string, conjunction: string };

export const en: TDurationLang = {
  y: pluralize.create('year', 'years'),
  mo: pluralize.create('month', 'months'),
  w: pluralize.create('week', 'weeks'),
  d: pluralize.create('day', 'days'),
  h: pluralize.create('hour', 'hours'),
  m: pluralize.create('minute', 'minutes'),
  s: pluralize.create('second', 'seconds'),
  ms: pluralize.create('millisecond', 'milliseconds'),
  decimals: ',',
  conjunction: ' and '
};

export const it: TDurationLang = {
  y: pluralize.create('anno', 'anni'),
  mo: pluralize.create('mese', 'mesi'),
  w: pluralize.create('settimana', 'settimane'),
  d: pluralize.create('giorno', 'giorni'),
  h: pluralize.create('ora', 'ore'),
  m: pluralize.create('minuto', 'minuti'),
  s: pluralize.create('secondo', 'secondi'),
  ms: pluralize.create('millisecondo', 'millisecondi'),
  decimals: '.',
  conjunction: ' e '
};
