import { instantiateFormatter } from '../helpers/create-formatters';

import { normalizeNumber, normalizeNumberWithPrecision } from '../utils/normalize';

import * as durationLanguage from '../languages/duration';


/* --------
 * Main Interface
 * -------- */

/** Define options to format a duration */
export interface IFormatDurationConfig {
  /**
   * Override default conjunction
   * for chosen language
   * Default `null`
   */
  conjunction?: string;
  /**
   * Override default decimal separator
   * for chosen language
   * Default `null`
   */
  decimals?: string;
  /**
   * Delimiter between formatted elements.
   * Default to `, `
   */
  delimiter?: string;
  /**
   * Define the Duration Language
   * Default to `en`
   */
  language?: TDurationSupportedLang;
  /**
   * Set how many units show, starting
   * from largest one.
   * Default to `null`
   */
  largest?: number;
  /**
   * Set the precision of the lower unit
   * Default to `2`
   */
  maxDecimals?: number;
  /**
   * Strip decimals, rounding to nearest integer
   * Default to `false`
   */
  round?: boolean;
  /**
   * A word to be placed between count and
   * unit string in rendered formatted string
   * Default to `' '`
   */
  spacer?: string;
  /**
   * Set the source unit value
   * Default to `ms`
   */
  sourceUnit?: TDurationKey;
  /** Set Unit Measures */
  unitMeasures?: Record<TDurationKey, number>;
  /**
   * Set units to display
   * Default to `['y', 'mo', 'w', 'd', 'h', 'm', 's']`
   */
  units?: TDurationKey[];
}

export type TDurationSupportedLang = 'en' | 'it';

export type TDurationKey = 'y' | 'mo' | 'w' | 'd' | 'h' | 'm' | 's' | 'ms';

interface IDurationPiece {
  unitCount: number;
  unitName: TDurationKey;
}


/* --------
 * Main fUNCTION
 * -------- */

/** Format a value as a duration */
export function formatDuration(value: any, config?: IFormatDurationConfig): string {
  /** Get Configuration */
  const {
    conjunction = null,
    decimals = null,
    delimiter = ', ',
    language = 'en',
    largest = null,
    maxDecimals = 2,
    round = false,
    spacer = '',
    sourceUnit = 'ms',
    unitMeasures: {
      y = 31557600000,
      mo = 2629800000,
      w = 604800000,
      d = 86400000,
      h = 3600000,
      m = 60000,
      s = 1000,
      ms = 1
    } = {},
    units = ['y', 'mo', 'w', 'd', 'h', 'm', 's']
  } = config;

  /** Collect measures */
  const unitMeasures: Record<TDurationKey, number> = { y, mo, w, d, h, m, s, ms };

  /** Normalize Number */
  let _value: number = Math.abs(normalizeNumber(value));

  /** Check if must be transformed using source unit */
  if (sourceUnit !== 'ms') {
    _value *= unitMeasures[sourceUnit];
  }

  /** Get the right dictionary */
  let dictionary: durationLanguage.TDurationLang;
  if (!(language in durationLanguage)) {
    global.console.warn(`You're tring to use an invalid language for formatDuration: '${language}' is not recognized. Falling back to 'en'`);
    dictionary = durationLanguage.en;
  }
  else {
    dictionary = durationLanguage[language];
  }

  /** Get Decimals separator */
  const _decimals = typeof decimals === 'string' ? decimals : dictionary.decimals;

  /** Build pieces container */
  const pieces: IDurationPiece[] = [];

  const { length: unitsLength } = units;

  /** Loop each units */
  for (let i = 0; i < unitsLength; i++) {
    let unitCount: number = 0;
    const unitName = config.units[i];
    const unitMS = config.unitMeasures[unitName];

    /** If is the last piece, check how many decimals must keep */
    if (i === unitsLength - 1) {
      if (Number.isFinite(maxDecimals)) {
        unitCount = parseFloat(
          normalizeNumberWithPrecision(_value / unitMS, maxDecimals)
        );
      }
      else {
        unitCount = _value / unitMS;
      }
    }
    else {
      unitCount = Math.floor(_value / unitMS);
    }

    /** Add Unit Piece */
    pieces.push({ unitCount, unitName });

    /** Remove this count */
    _value -= unitCount * unitMS;
  }

  /** Get the first unit that has count */
  let firstUnitIndex = 0;
  for (let i = 0; i < pieces.length; i++) {
    if (pieces[i].unitCount) {
      firstUnitIndex = i;
      break;
    }
  }

  /** Check if must round units */
  if (round) {
    let ratioToLargerUnit;
    let previousPiece;

    for (let i = pieces.length; i >= 0; i--) {
      const piece = pieces[i];
      piece.unitCount = Math.round(piece.unitCount);

      if (i === 0) {
        break;
      }

      previousPiece = pieces[i - 1];

      ratioToLargerUnit = unitMeasures[previousPiece.unitName] / unitMeasures[piece.unitName];

      if ((piece.unitCount % ratioToLargerUnit) === 0 || (largest && ((largest - 1) < (i - ratioToLargerUnit)))) {
        previousPiece.unitCount += piece.unitCount / ratioToLargerUnit;
        piece.unitCount = 0;
      }
    }
  }

  /** Build the result */
  const result: string[] = [];

  for (const piece of pieces) {
    if (piece.unitCount) {
      result.push(renderPiece(piece, dictionary, _decimals, spacer));
    }

    if (result.length === largest) {
      break;
    }
  }

  /** If result has no length, return 0 */
  if (!result.length) {
    return renderPiece({
      unitCount: 0,
      unitName: units[unitsLength - 1]
    }, dictionary, _decimals, spacer);
  }

  /** Render the result */
  if (!conjunction || result.length === 1) {
    return result.join(delimiter);
  }

  if (result.length === 2) {
    return result.join(conjunction);
  }

  return [
    result.slice(0, -1).join(delimiter),
    result.slice(-1)
  ].join(conjunction);
}


/* --------
 * Formatter Instantiator
 * -------- */

/** Instantiate a formatter with default configuration */
formatDuration.create = instantiateFormatter<typeof formatDuration, number, IFormatDurationConfig>(formatDuration);


/* --------
 * Side Useful Private Functions
 * -------- */

/** Render a piece */
function renderPiece(
  piece: IDurationPiece,
  dictionary: durationLanguage.TDurationLang,
  _decimals: string,
  _spacer: string
): string {
  const str = piece.unitCount.toString().replace('.', _decimals);
  const unit = typeof dictionary[piece.unitName] === 'function'
      ? (dictionary[piece.unitName] as ((value: number) => string))(piece.unitCount)
      : dictionary[piece.unitName];
  return `${str}${_spacer}${unit}`;
}
