import { instantiateFormatter } from '../helpers/create-formatters';
import { normalizeNumberWithPrecision, normalizeNumber, normalizeInteger } from '../utils/normalize';
import { escapeRegex } from '../utils/escape-regex';


/* --------
 * Main Interfaces
 * -------- */

/** Define options to format a number */
export interface IFormatNumberConfig {
  /** Define decimals separator, default to `.` */
  decimalSeparator?: string;
  /**
   * Set if decimals count could be
   * flexible starting
   * from `minPrecision` to `precision`
   */
  flexibleDecimals?: boolean;
  /**
   * Set the minimum decimal count,
   * this option will be considered
   * only when `flexibleDecimals` is true
   */
  minPrecision?: number;
  /**
   * Define the format pattern to use
   * to display formatted number.
   * Pattern string could use some placeholder:
   *  - `%n` to define the number
   *  - `%m` to define the minus symbol (if negative)
   *  - `%s` to define the suffix
   *  - `%p` to define the prefix
   *
   * Default pattern is
   * `%p %m %n %s`
   *
   * _Attention_: multiple consecutive spacing
   * are replaced with one single space
   */
  pattern?: string;
  /**
   * Set the number precision.
   * This option will be used also
   * as `maxPrecision` number when formatting
   * with `flexbileDecimals` to true
   */
  precision?: number;
  /** Append a prefix to formatted number */
  prefix?: string;
  /** Prepend a suffix to formatted number */
  suffix?: string;
  /** Define thousand separator, default to `,` */
  thousandSeparator?: string;
}


/* --------
 * Main Function
 * -------- */

/** Format a number using configuration */
export function formatNumber(num: number, config?: IFormatNumberConfig): string {
  /** Get Configuration */
  const {
    decimalSeparator = '.',
    flexibleDecimals = false,
    minPrecision = 0,
    pattern = '%p %m %n %s',
    precision,
    prefix = '',
    suffix = '',
    thousandSeparator = ','
  } = config ?? {};

  /** Normalize Number */
  const _num = normalizeNumber(num);
  const _precision = normalizeInteger(Math.abs(precision), 0);
  const _minPrecision = normalizeInteger(Math.abs(minPrecision), 0);

  /** Get Data */
  const isNegative = _num < 0;
  const base = parseInt(normalizeNumberWithPrecision(Math.abs(num), _precision), 10).toString();
  const mod = base.length > 3 ? base.length % 3 : 0;

  /** Build the Formatted Number */
  let formatted = [
    getFirstCommaString(base, thousandSeparator, mod),
    getCommasSubString(base, thousandSeparator, mod),
    getDecimals(_num, decimalSeparator, _precision)
  ].join('');

  /** Check if decimals are flexible */
  if (flexibleDecimals && precision > 0) {
    /** Build the RegEx */
    const escapedSeparator = escapeRegex(decimalSeparator);
    const regex = new RegExp(`(${escapedSeparator}0*[^0]+)(0+$)|(${escapedSeparator}0+$)|(${escapedSeparator}$)`);
    /** Replace leading 0 */
    formatted = formatted.replace(regex, '$1');
    /** If minPrecision differ from 0, check decimals count */
    if (_minPrecision !== 0) {
      const [integer, replacedDecimals] = formatted.split(decimalSeparator);
      const newDecimals = !replacedDecimals || replacedDecimals.length < _minPrecision
        ? (replacedDecimals ?? '').padEnd(_minPrecision, '0')
        : replacedDecimals;
      /** Reassing formatted number */
      formatted = [integer, newDecimals].join(decimalSeparator);
    }
  }

  /** Return the formatted string */
  return pattern
    .replace(/%p/g, prefix)
    .replace(/%m/g, isNegative ? '-' : '')
    .replace(/%n/g, formatted)
    .replace(/%s/g, suffix)
    .replace(/\s+/g, ' ')
    .trim();
}


/* --------
 * Formatter Instantiator
 * -------- */

/** Instantiate a formatter with default configuration */
formatNumber.create = instantiateFormatter<typeof formatNumber, number, IFormatNumberConfig>(formatNumber);


/* --------
 * Side Useful Private Functions
 * -------- */

/** Return the part of the number from start to first comma */
function getFirstCommaString(num: string, sep: string, position: number): string {
  return position ? `${num.substr(0, position)}${sep}` : '';
}

/** Return number separated by commas */
function getCommasSubString(num: string, sep: string, position: number): string {
  return num.substr(position).replace(/(\d{3})(?=\d)/g, `$1${sep}`);
}

/** Get Decimals part */
function getDecimals(num: number, sep: string, precision: number): string {
  return precision
    ? `${sep}${normalizeNumberWithPrecision(Math.abs(num), precision).toString().split('.')[1]}`
    : '';
}
